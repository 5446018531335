import React from 'react';

const GreenLogo: React.FC<{ className?: string }> = ({ className }) => {
  return (
    <div className={className}>
      <svg
        width="60"
        height="60"
        viewBox="0 0 60 60"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_862_6883)">
          <path
            fillRule="evenodd"
            clipPath="evenodd"
            d="M60.0002 2.50066C59.9981 1.83809 59.734 1.20326 59.2655 0.734746C58.797 0.266235 58.1621 0.00210008 57.4996 0H29.9883C22.0318 0 14.4012 3.1607 8.77508 8.7868C3.14899 14.4129 -0.0117187 22.0435 -0.0117188 30V57.5033C-0.0106774 57.8334 0.0555066 58.1601 0.18304 58.4646C0.310574 58.7691 0.49695 59.0454 0.731482 59.2777C0.966014 59.51 1.24409 59.6937 1.54977 59.8184C1.85545 59.943 2.18273 60.0061 2.51284 60.004H29.9883C33.9283 60.004 37.8297 59.2279 41.4697 57.72C45.1097 56.2121 48.4171 54.002 51.2029 51.2158C53.9887 48.4296 56.1984 45.122 57.7058 41.4817C59.2132 37.8415 59.9888 33.94 59.9883 30V2.50066"
            fill="#47D62D"
          />
          <path
            fillRule="evenodd"
            clipPath="evenodd"
            d="M24.1028 15.0915L36.5304 27.5192C36.5512 27.5413 36.5627 27.5705 36.5627 27.6008C36.5627 27.6311 36.5512 27.6603 36.5304 27.6824L30.0677 34.1451C30.057 34.1565 30.044 34.1655 30.0296 34.1716C30.0152 34.1778 29.9998 34.181 29.9841 34.181C29.9685 34.181 29.953 34.1778 29.9386 34.1716C29.9243 34.1655 29.9113 34.1565 29.9005 34.1451L17.4728 21.7175C17.4521 21.6953 17.4406 21.6661 17.4406 21.6358C17.4406 21.6055 17.4521 21.5763 17.4728 21.5542L23.9355 15.0915C23.9463 15.0802 23.9593 15.0712 23.9737 15.065C23.988 15.0588 24.0035 15.0557 24.0192 15.0557C24.0348 15.0557 24.0503 15.0588 24.0646 15.065C24.079 15.0712 24.092 15.0802 24.1028 15.0915ZM47.226 15.0079C47.2414 15.0073 47.2569 15.0099 47.2714 15.0155C47.2858 15.0211 47.299 15.0295 47.3102 15.0403C47.3213 15.051 47.3302 15.064 47.3363 15.0782C47.3423 15.0925 47.3454 15.1079 47.3454 15.1234V44.8844C47.3454 44.9161 47.3328 44.9465 47.3104 44.9689C47.288 44.9913 47.2576 45.0039 47.226 45.0039H38.0874C38.0564 45.0029 38.027 44.9898 38.0055 44.9675C37.9839 44.9452 37.9719 44.9154 37.9719 44.8844V15.1234C37.9719 15.1082 37.9749 15.0932 37.9807 15.0792C37.9865 15.0652 37.995 15.0524 38.0057 15.0417C38.0164 15.031 38.0292 15.0225 38.0432 15.0167C38.0572 15.0109 38.0722 15.0079 38.0874 15.0079H47.226ZM21.9127 27.1926C21.9433 27.1926 21.9727 27.2048 21.9944 27.2265C22.016 27.2481 22.0282 27.2775 22.0282 27.3081V44.8844C22.0282 44.9154 22.0162 44.9452 21.9946 44.9675C21.9731 44.9898 21.9437 45.0029 21.9127 45.0039H12.7542C12.7225 45.0039 12.6922 44.9913 12.6698 44.9689C12.6474 44.9465 12.6348 44.9161 12.6348 44.8844V27.3081C12.6348 27.2926 12.6379 27.2773 12.6439 27.263C12.65 27.2487 12.6588 27.2358 12.67 27.225C12.6811 27.2143 12.6943 27.2058 12.7088 27.2003C12.7233 27.1947 12.7387 27.1921 12.7542 27.1926H21.9127Z"
            fill="#EFEFEF"
          />
        </g>
        <defs>
          <clipPath id="clip0_862_6883">
            <rect width="60" height="60" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};

export default GreenLogo;
