export const definedTranslation = {
  title: {
    id: 'sustainability-component-title',
    defaultMessage: 'Together we saved',
  },
  subtitle: {
    id: 'sustainability-component-subtitle',
    defaultMessage: 'packages from being sent',
  },
};
