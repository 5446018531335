import styled from '@emotion/styled';
import TypographyComponent from 'components/Typography/Typography';
import { theme, pxToEm } from 'theme';
import GreenLogo from '../assets/svg/GreenLogo';

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  overflow: hidden;
  background: ${theme.palette.grey['200']};
  border-radius: ${pxToEm(20)};
  padding: ${pxToEm(16.5)} ${pxToEm(16)} 0;
  @media screen and (min-width: 768px) {
    justify-content: flex-start;
    min-height: ${pxToEm(260)};
  }
  @media screen and (min-width: 1024px) {
    padding-top: ${pxToEm(40)};
    padding-left: ${pxToEm(40)};
    min-height: ${pxToEm(500)};
  }
`;

export const GrayBox = styled.div`
  width: 100%;
  max-width: ${pxToEm(1376)};
  display: flex;
  flex-direction: column-reverse;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-bottom: ${pxToEm(30)};
  @media screen and (min-width: 768px) {
    flex-direction: row;
    flex-wrap: nowrap;
    padding-top: ${pxToEm(23.5)};
  }
  @media screen and (min-width: 1024px) {
    padding-top: ${pxToEm(72)};
  }
  @media screen and (min-width: 1399px) {
    max-height: ${pxToEm(500)};
  }
`;

export const Package = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex: 1;
  min-width: auto;
  transition: width 5s ease-out;
  mix-blend-mode: multiply;
  object-fit: cover;
  @media screen and (min-width: 768px) {
    justify-content: flex-end;
    min-width: min-content;
  }
  @media screen and (max-width: 767px) {
    padding-top: ${pxToEm(36)};
    padding-bottom: ${pxToEm(58)};
  }
  img {
    width: 100%;
    max-width: ${pxToEm(520)};
    object-fit: contain;
    @media screen and (min-width: 768px) {
      max-height: ${pxToEm(125)};
    }
    @media screen and (min-width: 1024px) {
      max-height: none;
    }
    @media screen and (min-width: 1280px) {
      margin-top: ${pxToEm(-30)};
      max-width: ${pxToEm(750)};
    }
  }
`;

export const BoxNumbers = styled.div`
  flex-direction: column;
`;

export const StyledGreenLogo = styled(GreenLogo)`
    @media screen and (max-width: 768px) {
        svg {
          width: ${pxToEm(32)};
          height: ${pxToEm(32)};
        }
    }
  }
`;

export const GrayBoxText = styled.div`
  font-size: ${pxToEm(24)};
  line-height: 2rem;
  font-weight: 600;
`;

export const GrayBoxLastText = styled.div`
  font-size: ${pxToEm(24)};
  font-weight: 600;
  margin-top: 17px;
`;

export const WrapperNumber = styled.div`
  flex-direction: row;
`;

export const Number = styled(TypographyComponent)`
  font-weight: 600;
  font-size: ${pxToEm(60)};
  line-height: 60px;
  letter-spacing: -1.2px;
  color: ${theme.palette.success.light};
  display: block;
  @media screen and (min-width: 1024px) {
    font-size: ${pxToEm(100)};
    line-height: 80px;
    letter-spacing: unset;
    margin: 20px 0 !important;
  }
`;
