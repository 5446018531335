import React, { useState, useEffect } from 'react';
import { getLocaleData } from '@mb/lib/i18n';
import { IntlProvider } from 'react-intl';
import { Banner } from './components/Banner';

const SustainabilityBanner = () => {
  const [langData, setLangData] = useState({
    currentLocale: 'en',
    messages: undefined,
  });

  useEffect(() => {
    handleLangData();
  }, []);

  const handleLangData = async () => {
    const data = await getLocaleData();
    // @ts-expect-error // TODO: fix types
    setLangData(data);
  };

  return (
    <IntlProvider
      messages={langData?.messages}
      locale={langData?.currentLocale}
      defaultLocale="en"
    >
      <Banner />
    </IntlProvider>
  );
};

export default SustainabilityBanner;
