import React, { useEffect, useState } from 'react';
import { getUserLang } from '@mb/lib/i18n';
import moment from 'moment';
import { useIntl } from 'react-intl';
import * as Styled from './Banner.styles';
import BoxImage from '../assets/images/packages-v3.webp';
import useInterval from '../hooks/useInterval';
import { definedTranslation } from '../i18n';

export const Banner = () => {
  const [savedPackages, setSavedPackages] = useState('0');
  const intl = useIntl();

  const labels = {
    title: intl.formatMessage(definedTranslation.title),
    subtitle: intl.formatMessage(definedTranslation.subtitle),
  };

  const getHolidays = () => {
    const apiHolidays = window?.HOLIDAYS as Array<string> | null;
    const holidays = apiHolidays
      ? apiHolidays.map((date) => moment(new Date(`${date}T00:00:00`)))
      : [];
    return holidays;
  };

  function isWorkingDay() {
    const now = moment();
    let isWorkingDay = now.isoWeekday() < 7;
    const holidays = getHolidays();
    holidays.forEach((holiday) => {
      if (holiday.isSame(now, 'day')) isWorkingDay = false;
    });
    return isWorkingDay;
  }

  function calculatePackagesNumber() {
    const holidays = getHolidays();
    const apiInitialDate = window?.STARTING_DAY || '2023-01-06';
    const initialNumber = window?.STARTING_NUMBER | 3000000;
    const initialHour = moment().set({ hours: 8, minutes: 0 });
    const endHour = moment().set({ hours: 23, minutes: 59 });
    const now = moment();
    const packagesPerMinute = 3;
    const diffMinutesSingleDay = endHour.diff(initialHour, 'minutes');
    let numberOfWorkingDays = 0;
    const userLang = getUserLang();
    const isGerman = userLang === 'de';

    const startDate = moment(new Date(`${apiInitialDate}T00:00:00`)).startOf(
      'day',
    );
    const today = moment(new Date()).startOf('day');
    for (
      const currentDate = startDate;
      currentDate.diff(today, 'days') !== 0;
      currentDate.add(1, 'days')
    ) {
      if (currentDate.isoWeekday() < 7) {
        const isAHoliday = holidays.some((holiday) =>
          holiday.isSame(currentDate, 'day'),
        );
        if (!isAHoliday) {
          numberOfWorkingDays += 1;
        }
      }
    }
    let totalPastMinutes = numberOfWorkingDays * diffMinutesSingleDay;
    if (isWorkingDay()) {
      const diffMinutesToday =
        now.diff(initialHour, 'minutes') > 0
          ? now.diff(initialHour, 'minutes')
          : 0;
      totalPastMinutes = totalPastMinutes + diffMinutesToday;
    }
    const totalSavedPackages =
      initialNumber + totalPastMinutes * packagesPerMinute;
    const formattedNumber = totalSavedPackages.toLocaleString('en-US');
    setSavedPackages(
      isGerman
        ? String(formattedNumber)?.replaceAll(',', '.')
        : formattedNumber,
    );
  }

  useInterval(() => {
    if (isWorkingDay()) {
      calculatePackagesNumber();
    }
  }, 1000);

  useEffect(() => {
    calculatePackagesNumber();
  }, []);

  return (
    <Styled.Wrapper className="banner-wrapper">
      <Styled.StyledGreenLogo />
      <Styled.GrayBox className="banner-graybox">
        <Styled.BoxNumbers>
          {/* @ts-expect-error // TODO: fix types */}
          <Styled.GrayBoxText fontFamily="Graphik">
            {labels.title}
          </Styled.GrayBoxText>
          <Styled.WrapperNumber>
            <Styled.Number fontFamily="Graphik">{savedPackages}</Styled.Number>
          </Styled.WrapperNumber>
          {/* @ts-expect-error // TODO: fix types */}
          <Styled.GrayBoxLastText fontFamily="Graphik">
            {labels.subtitle}
          </Styled.GrayBoxLastText>
        </Styled.BoxNumbers>
        <Styled.Package>
          <img src={'/' + BoxImage} />
        </Styled.Package>
      </Styled.GrayBox>
    </Styled.Wrapper>
  );
};
